jQuery(document).ready(function($) {
    // Form Wizard
    formWizard();
    calendarInput();
    contentBottomPadding();
});

window.onresize = function() {};

function scrollToClass(element_class, removed_height) {
    var scroll_to = $(element_class).offset().top - removed_height;
    if($(window).scrollTop() != scroll_to) {
        $('html, body').stop().animate({scrollTop: scroll_to}, 0);
    }
}

function barProgress(progress_line_object, direction) {
    var number_of_steps = progress_line_object.data('number-of-steps');
    var now_value = progress_line_object.data('now-value');
    var new_value = 0;
    if(direction == 'right') {
        new_value = now_value + ( 100 / number_of_steps );
    }
    else if(direction == 'left') {
        new_value = now_value - ( 100 / number_of_steps );
    }
    progress_line_object.attr('style', 'width: ' + new_value + '%;').data('now-value', new_value);
}

function formWizard() {
    $('.f-wizard .f-wizard-panel:first').fadeIn('slow');

    // next step
    $('.f-wizard .btn-next').on('click', function() {
        var parent_panel = $(this).parents('.f-wizard-panel');
        var next_step = true;
        // navigation steps / progress steps
        var current_active_step = $(this).parents('.f-wizard').find('.f-wizard-step.active');
        var progress_line = $(this).parents('.f-wizard').find('.f-wizard-progress-line');

        // fields validation
        /*parent_panel.find('input[type="text"], input[type="password"], textarea').each(function() {
            if( $(this).val() == "" ) {
                $(this).addClass('input-error');
                next_step = false;
            }
            else {
                $(this).removeClass('input-error');
            }
        });*/

        if( next_step ) {
            // Autosave data

            // Change panel
            parent_panel.fadeOut(400, function() {
                // change icons
                current_active_step.removeClass('active').addClass('activated').next().addClass('active');
                // progress bar
                barProgress(progress_line, 'right');
                // show next step
                $(this).next().fadeIn();
                // scroll window to beginning of the form
                scrollToClass( $('.f-wizard'), 20 );
            });
        }

    });

    // previous step
    $('.f-wizard .btn-previous').on('click', function() {
        // navigation steps / progress steps
        var current_active_step = $(this).parents('.f-wizard').find('.f-wizard-step.active');
        var progress_line = $(this).parents('.f-wizard').find('.f-wizard-progress-line');

        // Autosave data

        // Change panel
        $(this).parents('.f-wizard-panel').fadeOut(400, function() {
            // change icons
            current_active_step.removeClass('active').prev().removeClass('activated').addClass('active');
            // progress bar
            barProgress(progress_line, 'left');
            // show previous step
            $(this).prev().fadeIn();
            // scroll window to beginning of the form
            scrollToClass( $('.f-wizard'), 20 );
        });
    });

    // submit
    $('.f-wizard').on('submit', function(e) {

        // fields validation
        $(this).find('input[type="text"], input[type="password"], textarea').each(function() {
            if( $(this).val() == "" ) {
                e.preventDefault();
                $(this).addClass('input-error');
            }
            else {
                $(this).removeClass('input-error');
            }
        });
        // fields validation

    });
}

function calendarInput() {
    flatpickr('.calendar');
}

function contentBottomPadding() {
    var content = $('.content');
    var footer = $('.footer');

    var padding = Math.round(footer.height())+30;

    content.css('padding-bottom', padding+'px');
}